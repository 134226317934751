// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cancellation-policy-js": () => import("./../../../src/pages/cancellation-policy.js" /* webpackChunkName: "component---src-pages-cancellation-policy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-adult-dog-training-js": () => import("./../../../src/pages/services/adult-dog-training.js" /* webpackChunkName: "component---src-pages-services-adult-dog-training-js" */),
  "component---src-pages-services-behaviour-assessment-js": () => import("./../../../src/pages/services/behaviour-assessment.js" /* webpackChunkName: "component---src-pages-services-behaviour-assessment-js" */),
  "component---src-pages-services-behaviour-socialisation-training-js": () => import("./../../../src/pages/services/behaviour-socialisation-training.js" /* webpackChunkName: "component---src-pages-services-behaviour-socialisation-training-js" */),
  "component---src-pages-services-dog-walking-pack-walks-js": () => import("./../../../src/pages/services/dog-walking-pack-walks.js" /* webpackChunkName: "component---src-pages-services-dog-walking-pack-walks-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-juvenile-training-js": () => import("./../../../src/pages/services/juvenile-training.js" /* webpackChunkName: "component---src-pages-services-juvenile-training-js" */),
  "component---src-pages-services-puppy-training-js": () => import("./../../../src/pages/services/puppy-training.js" /* webpackChunkName: "component---src-pages-services-puppy-training-js" */),
  "component---src-pages-services-walk-training-js": () => import("./../../../src/pages/services/walk-training.js" /* webpackChunkName: "component---src-pages-services-walk-training-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

